import { Component, OnInit } from '@angular/core';
import {SvgBaseComponent} from '../../_utils/svg-base-component';

@Component({
  selector: 'app-svg-asia-and-africa-map',
  templateUrl: './svg-asia-and-africa-map.component.svg',
  styles: [
  ]
})
export class SvgAsiaAndAfricaMapComponent extends SvgBaseComponent{

}
