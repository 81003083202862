import {Component, HostListener, OnInit} from '@angular/core';
import {faTasks, faGraduationCap} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-services-section',
  templateUrl: './services-section.component.html',
  styleUrls: ['./services-section.component.scss']
})
export class ServicesSectionComponent implements OnInit {
  faGraduationCap = faGraduationCap;
  taskIcon = faTasks;
  svgAspectRatio =  122 / 1353;
  innerWidth = window.innerWidth;
  // @ts-ignore
  properHeight: number;

  anchor = 'bottom-center';
  constructor() { }

  ngOnInit(): void {
    this.setProperHeight();

  }

  setProperHeight(): void {
    this.properHeight = this.svgAspectRatio * this.innerWidth;
    console.log('============= aspect ratio =================');
    console.log(this.svgAspectRatio);
    console.log('=============== properHeight ========');
    console.log(this.properHeight);
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.innerWidth = window.innerWidth;
    this.setProperHeight();
  }

}
