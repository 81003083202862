import {Injectable} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loadingRequestCount = 0;

  constructor(private spinnerService: NgxSpinnerService) {
  }

  loading(): void {
    this.loadingRequestCount++;
    this.spinnerService.show(undefined, {
      fullScreen: true,
      bdColor: 'rgba(0,0,0,1)',
      color: '#FFFFFF',
      type: 'ball-clip-rotate-multiple'
    });
  }

  ready(): void {
    this.loadingRequestCount--;
    if (this.loadingRequestCount <= 0) {
      this.loadingRequestCount = 0;
      this.spinnerService.hide();
    }
  }
}

