<section id="intro" class="py-5">

  <div class="container py-5">
    <div class="row py-5 ">
      <div class="col-12 mb-5">
        <div class="align-items-center row">
          <div class="col-12 col-md-4 text-center mb-4 mb-md-0"
               id="lime-svg"
               data-start="opacity:0;transform:translateX(-300px) rotateZ(-100deg);filters: blur(50px);"
               data-90-center-top="opacity:0.5;transform:translateX(-50px) rotateZ(20deg);filters: blur(15px)"
               data-center-top="opacity:1;transform:translateX(0px) rotateZ(0px);filters: blur(0px);"
          >

              <app-lime-svg
                [width]="'260px'"
              ></app-lime-svg>

          </div>
          <div class="col-12 col-md text-center text-md-left">
            <p class="lead mb-4"

               data-100-center-top="opacity:0;transform:translateX(50px);"
               data-50-center-top="opacity:1;transform:translateX(0px);"
            > Leem Advertising is an Agency that provides Outdoor and Radio Advertising for
              your Brand in the Middle East and Africa.
            </p>
            <p class="lead"

               data-200-center-top="opacity:0;transform:translateX(50px);"
               data-150-center-top="opacity:1;transform:translateX(0px);"
            >Cost effective, straight forward & a clear vision, Leem started in Yemen and now
              Advertising throughout the region.</p>
          </div>
        </div>


      </div>

    </div>
  </div>
</section>
