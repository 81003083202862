import {Component, OnInit} from '@angular/core';
import * as clients from '../../../assets/json/clients.json';

@Component({
  selector: 'app-clients-section',
  templateUrl: './clients-section.component.html',
  styleUrls: ['./clients-section.component.scss']
})
export class ClientsSectionComponent implements OnInit {

  clientsPics: string[] = (clients as any).default;
  constructor() {
  }

  ngOnInit(): void {
  }

}
