import {Component, Input, OnInit} from '@angular/core';



@Component({
  selector: 'app-svg-logo',
  templateUrl: './svg.logo.component.svg',
  styleUrls: ['./svg.logo.component.scss']
})
export class SvgLogoComponent implements OnInit {
  @Input() fillColor = 'white';
  @Input() height = 'auto';
  @Input() width = 'auto';
  constructor() { }

  ngOnInit(): void {
  }

}
