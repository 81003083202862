<app-divider-svg
  #topDivider
  *ngIf="showDivider"
  [fillColor]="backgroundColor"
  [width]="'100%'"
  [ngStyle]="{
  marginBottom: '-7px',
        display: 'block'
  }"
></app-divider-svg>
<div class="col-12 px-0 py-5 m-0" [ngStyle]="{
      'backgroundColor': backgroundColor
      }">
  <div class="container">
    <div class="row align-items-center">
      <div #textColumn class="col-12 col-md {{textColClasses}}"
           [attr.data-center-top]='"opacity:0;transform:translateX("+(textFromRight?"":"-")+"100px);"'
           data--100-center-top="opacity:1;transform:translateX(0px)"
           [ngStyle]="{
           color: textColor
           }"
      >
        <p class="block-title text-center text-md-left {{titleClasses}}">
          <ng-content select="block-title"></ng-content>
        </p>
        <div class="block-content text-center text-md-left {{contentClasses}}"

        >
          <ng-content select="block-content"></ng-content>
        </div>
      </div>
      <div #mediaColumn class="col-12 col-md {{mediaColClasses}}">
        <ng-content select="block-media"></ng-content>
      </div>
    </div>
  </div>

</div>
<app-divider-svg
  #bottomDivider
  [fillColor]="backgroundColor"
  [width]="'100%'"
  *ngIf="showDivider"
  [ngStyle]="{
      marginTop: '-7px',
      transform: 'rotateX(180deg) rotateY(180deg)',
      display: 'block'
      }"

></app-divider-svg>
