import {Component} from '@angular/core';
import {BaseComponent} from '../base.component';
import {Title} from '@angular/platform-browser';
import {faTasks} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent extends BaseComponent{


  taskIcon = faTasks;
  anchor = 'bottom-center';


  constructor(titleService: Title) {
    super(titleService);
  }
}
