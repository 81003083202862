<section id="contact-us" class="py-5">
  <div class="container py-5">
    <h1 class="text-center mb-5">Contact Us</h1>
    <form class="contact-form ">
      <mat-form-field class="field-full-width" appearance="fill"
      >
        <mat-label>Message Subject</mat-label>
        <input name="subject"
               [(ngModel)]="message.subject"
               type="text"
               matInput
               placeholder="Message Subject"
        />
      </mat-form-field>
      <mat-form-field class="field-full-width" appearance="fill"
      >
        <mat-label>Message</mat-label>
        <textarea name="body"
                  [(ngModel)]="message.body"
                  matInput
                  placeholder="Message ..."
        ></textarea>
      </mat-form-field>
    </form>
    <a
       href="mailto:info@leem.co?subject={{message.subject}}&body={{message.body}}"
       target="_blank"
       rel="noopener noreferrer"
       class="btn btn-block text-center py-3"
       [class.disabled]="(message.subject === '' || message.body === '')"
       [class.btn-outline-danger]="(message.subject === '' || message.body === '')"
       [class.btn-outline-primary]="(message.subject !== '' && message.body !== '')"
    >
      Send Message
    </a>
  </div>
</section>
