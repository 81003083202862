<section id="ooh-radio-section" class="py-5" xmlns="http://www.w3.org/1999/html">
  <div class="container py-5">
    <div class="row align-items-center">
      <div class="col-12 col-md text-center"

      >
        <h1 class="section-title"
            data-50-bottom-center="opacity:0;transform: rotate(200deg)"
            data--220-bottom-center="opacity:1;transform: rotate(-10deg)"
            data--250-bottom-center="opacity:1;transform: rotate(0deg)"
        >
          <span class="mb-2 d-inline-block"><strong>OOH</strong> & <strong>Radio</strong></span> <br>
          <span class="small text-muted">IN</span>
        </h1>


      </div>
     <div class="col-12 col-md text-center"
          data-bottom-bottom="opacity:0;transform: translateX(-150px)"
          data--100-bottom-bottom="opacity:1;transform: translateX(0px)"
     >
       <app-svg-asia-and-africa-map [fillColor]="'white'" [width]="'350px'"></app-svg-asia-and-africa-map>
       <h1 class=" mt-3">Asia & Africa</h1>

     </div>
    </div>
  </div>
</section>
