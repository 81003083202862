<ngx-spinner>
  <h3>Loading ...</h3>
</ngx-spinner>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer mode="over" opened="false" class="sidenav" fixedInViewport>
    <div class="text-center">
      <app-logo-co-svg [fillColor]="'black'" [width]="'150px'" [ngClass]="['d-inline']"></app-logo-co-svg>
    </div>
    <mat-nav-list>
      <app-navigation (onClick)="scrollTo($event)"></app-navigation>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar [style]="'background-color:transparent;color:white;z-index:300;'">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
      >
        <fa-icon [icon]="barsIcon"></fa-icon>
      </button>
    </mat-toolbar>
    <div id="skrollr-body">
      <app-header></app-header>
      <main id="page-content" role="main">
        <router-outlet></router-outlet>
      </main>
    </div>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>

