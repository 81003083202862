import {Component, OnInit} from '@angular/core';

export class ContactMessage {
  subject = '';
  body = '';
}

@Component({
  selector: 'app-contact-section',
  templateUrl: './contact-section.component.html',
  styleUrls: ['./contact-section.component.scss']
})
export class ContactSectionComponent implements OnInit {

  message: ContactMessage = new ContactMessage();

  constructor() {
  }

  ngOnInit(): void {
  }

}
