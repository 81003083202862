import { Component, OnInit } from '@angular/core';
import {SvgBaseComponent} from '../../_utils/svg-base-component';

@Component({
  selector: 'app-logo-co-svg',
  templateUrl: './logo-co-svg.component.svg',
  styles: [
  ]
})
export class LogoCoSvgComponent extends SvgBaseComponent{

}
