import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-svg-social-icon',
  templateUrl: './svg-social-icon.component.svg',
  styleUrls: ['./svg-social-icon.component.scss']
})
export class SvgSocialIconComponent implements OnInit {

  @Input() width = 'auto';
  @Input() height = 'auto';
  @Input() fill = 'black';

  constructor() {
  }

  ngOnInit(): void {

  }

}
