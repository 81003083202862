import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styles: []
})
export class ModalComponent {

  // title: string;
  public closeBtnName = '';
  public item = '';

  constructor(public bsModalRef: BsModalRef) {
  }


}
