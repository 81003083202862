<section id="portfolio" class="py-5">
  <div class="container py-5">
    <h1 class="text-center mb-5">Portfolio</h1>
    <div class="row align-items-stretch justify-content-center">
      <div *ngFor="let image of items"
           class="col-12 col-sm-6 col-md-4 mb-3 portfolio-col"

           (click)="onClick(image)"
      >
        <div class="portfolio-image"
             [ngStyle]="{
      backgroundImage: 'url(\''+image+'\')'
      }"
        >

        </div>
<!--        <img src="{{image}}" class="img-fluid portfolio-img"-->
<!--        />-->
      </div>
    </div>
  </div>
</section>
