<header id="page-header py-5">
  <div class="container-fluid text-center py-5">
<div class="row align-items-center justify-content-center">
  <div class="col-12 col-md-4 py-5 text-center">
      <app-logo-co-svg [width]="'100%'" fillColor="white"></app-logo-co-svg>
  </div>
</div>


  </div>
</header>
