import {Component, Input, OnInit} from '@angular/core';
import {SvgBaseComponent} from '../../_utils/svg-base-component';

@Component({
  selector: 'app-lime-svg',
  templateUrl: './lime-svg.component.svg',
  styles: [
  ]
})
export class LimeSvgComponent extends SvgBaseComponent{
  @Input() fillColor = '#91c540';
  @Input() width = '200px';

}
