<section id="clients" class="py-5">
  <div class="container py-5">
    <h1 class="text-center mb-5">Our Clients</h1>
    <div class="row align-items-stretch">

      <div *ngFor="let image of clientsPics"
      class="col-12 col-sm-6 col-md-4 text-center client-col mb-3"
      >
        <img src="{{image}}" class="client-img" />
      </div>
    </div>
  </div>
</section>
