
<div class="modal-header">
<!--  <h4 class="modal-title pull-left">{{title}}</h4>-->
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <img src="{{item}}" class="img-fluid" >
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
</div>
