import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSliderModule} from '@angular/material/slider';
import {NavigationComponent} from './navigation/navigation.component';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {HomePageComponent} from './home-page/home-page.component';
import {SvgLogoComponent} from './assets/svg.logo/svg.logo.component';
import {FooterComponent} from './layout/footer/footer.component';
import {OurWorkComponent} from './our-work/our-work.component';
import {HeaderComponent} from './layout/header/header.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {LoadingInterceptor} from './_interceptors/loading.interceptor';
import {SvgSocialIconComponent} from './assets/svg-social-icon/svg-social-icon.component';
import {OohRadioSectionComponent} from './home-page/ooh-radio-section/ooh-radio-section.component';
import {SvgAsiaAndAfricaMapComponent} from './assets/svg-asia-and-africa-map/svg-asia-and-africa-map.component';
import {LimeSvgComponent} from './assets/lime-svg/lime-svg.component';
import {IntroSectionComponent} from './home-page/intro-section/intro-section.component';
import {ServicesSectionComponent} from './home-page/services-section/services-section.component';
import {ServiceBlockComponent} from './home-page/services-section/service-block/service-block.component';
import {DividerSvgComponent} from './assets/divider-svg/divider-svg.component';
import {ClientsSectionComponent} from './home-page/clients-section/clients-section.component';
import {PortfolioSectionComponent} from './home-page/portfolio-section/portfolio-section.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {ModalModule} from 'ngx-bootstrap/modal';
import {ModalComponent} from './_utils/modal/modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ContactSectionComponent } from './home-page/contact-section/contact-section.component';
import { RadioSvgComponent } from './assets/radio-svg/radio-svg.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { LogoCoSvgComponent } from './assets/logo-co-svg/logo-co-svg.component';
import { IntroDialogComponent } from './intro-dialog/intro-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';


@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HomePageComponent,
    SvgLogoComponent,
    FooterComponent,
    OurWorkComponent,
    HeaderComponent,
    SvgSocialIconComponent,
    OohRadioSectionComponent,
    SvgAsiaAndAfricaMapComponent,
    LimeSvgComponent,
    IntroSectionComponent,
    ServicesSectionComponent,
    ServiceBlockComponent,
    DividerSvgComponent,
    ClientsSectionComponent,
    PortfolioSectionComponent,
    ModalComponent,
    ContactSectionComponent,
    RadioSvgComponent,
    LogoCoSvgComponent,
    IntroDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    ScrollingModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    LazyLoadImageModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
