import { Component, OnInit } from '@angular/core';
import * as portfolio from 'src/assets/json/portfolio.json';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ModalComponent} from '../../_utils/modal/modal.component';

@Component({
  selector: 'app-portfolio-section',
  templateUrl: './portfolio-section.component.html',
  styleUrls: ['./portfolio-section.component.scss']
})
export class PortfolioSectionComponent implements OnInit {

  items: string[] = (portfolio as any).default;
  // @ts-ignore
  modal: BsModalRef;

  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
  }

  onClick(url: string): void{
    const initialState = {
      item: url,
      closeBtnName: 'Close'
    };
    this.modal = this.modalService.show(ModalComponent, {initialState});
    this.modal.setClass('modal-lg');
  }

}
