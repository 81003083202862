import {AfterContentChecked, Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {LoadingService} from './_services/loading.service';
@Component({
  template: ''
})
export abstract class BaseComponent implements OnInit{

  // title = 'Leem International L.T.D.';
  title = 'Leem Advertising';

  constructor(protected titleService: Title) {
  }

  ngOnInit(): void {
    this.setTitle();
  }

  setTitle(): void {
    this.titleService.setTitle(this.title);
  }

}

