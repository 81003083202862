import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {SvgBaseComponent} from '../../_utils/svg-base-component';

@Component({
  selector: 'app-divider-svg',
  templateUrl: './divider-svg.component.svg',
})
export class DividerSvgComponent extends SvgBaseComponent implements AfterViewInit{
  @Input() alignmentBase = 'baseline';
  @ViewChild('svgElement') svgElement!: SVGElement;

  ngAfterViewInit(): void {
  }
}
