import { Component, OnInit } from '@angular/core';
import {SvgBaseComponent} from '../../_utils/svg-base-component';

@Component({
  selector: 'app-radio-svg',
  templateUrl: './radio-svg.component.svg',
  styles: [
  ]
})
export class RadioSvgComponent extends SvgBaseComponent{
}
