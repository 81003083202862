<section id="services" class="py-5"
>
  <div class="container-fluid py-5">
    <div class="text-center mb-5">
      <h1 class="section-title display-1"
          data-anchor-target="#services"
          data-center-top="opacity:0;transform:translateX(-50px)"
          data--200-center-top="opacity:1;transform:translateX(0px)"
      >Services</h1>
    </div>
    <div class="row mt-3"
         data-center-top="opacity:0;"
         data--200-center-top="opacity:1;"
    >

      <app-service-block
        #outdoorAdv
        [showDivider]="true"
        [backgroundColor]="'#91c540'"
        [textColor]="'black'"
        [textColClasses]="'order-2'"
        [mediaColClasses]="'order-1'"
        [textFromRight]="true"
        class="col-12 p-0"
      >
        <block-title>

          <fa-icon [icon]="faGraduationCap"></fa-icon>
          Outdoor Advertising


        </block-title>
        <block-content>
          Partnered with the region’s biggest network of Outdoor Advertising providers, Leem
          Advertising provides cost effective, reliable OOH and DOOH campaign management in the Middle East and
          Africa. Fast installations, full reporting <strong>“Video and Photos”</strong> and always great locations!
        </block-content>
        <block-media>
          <svg
            id="billboard-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 800 800"
            [ngStyle]="{
          width: '100%',
          height: 'auto'
          }"
          >
            <defs>
            </defs>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="609.5" y1="237.88" x2="227.14" y2="126.35"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="605.92" y1="434.36" x2="227.14" y2="465.92"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="227.14" y1="126.35" x2="227.14" y2="465.92"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="605.92" y1="236.83" x2="605.92" y2="434.36"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="227.14" y1="126.35" x2="179.77" y2="138.09"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="179.77" y1="138.09" x2="179.77" y2="449.46"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="227.14" y1="465.92" x2="179.77" y2="462.57"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="605.92" y1="434.36" x2="655.45" y2="435.86"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="227.14" y1="465.92" x2="282.54" y2="469.84"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="655.45" y1="435.86" x2="282.54" y2="471.34"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="655.91" y1="445.29" x2="282.66" y2="488.21"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="282.54" y1="471.34" x2="282.54" y2="488.22"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="655.45" y1="435.86" x2="655.45" y2="445.34"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="282.66" y1="488.21" x2="179.77" y2="479.24"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="179.77" y1="449.46" x2="179.77" y2="479.24"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="454.6" y1="471.34" x2="454.6" y2="748.42"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="425.25" y1="474.64" x2="425.25" y2="762.17"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="295.15" y1="146.19" x2="331.89" y2="106.86"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="379.95" y1="170.92" x2="409.76" y2="134.44"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="469.49" y1="197.04" x2="492.73" y2="163.82"/>
            <line
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="a" x1="539.99" y1="217.6" x2="558.62" y2="187.16"/>
            <path
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="b"
              d="M340,109.72c0,7.77-3.6,12.86-8.08,11.35s-8.25-9.2-8.25-17.14,3.71-13,8.25-11.29S340,102,340,109.72Z"/>
            <path
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="b"
              d="M417.79,137.28c0,7-2.93,11.7-6.57,10.48s-6.69-8-6.69-15.18,3-11.8,6.69-10.43S417.79,130.27,417.79,137.28Z"/>
            <path
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="b"
              d="M499,166.05c0,6.22-2.29,10.48-5.15,9.52s-5.24-6.88-5.24-13.2,2.36-10.55,5.24-9.48S499,159.84,499,166.05Z"/>
            <path
              data-center-top="opacity:0;"
              data--100-center-top="opacity:1;"
              class="b"
              d="M563.7,189c0,5.58-1.86,9.48-4.16,8.71s-4.21-6-4.21-11.67,1.89-9.54,4.21-8.68S563.7,183.38,563.7,189Z"/>
          </svg>
        </block-media>
      </app-service-block>


      <app-service-block
        #radioAdv
        [backgroundColor]="'darkblue'"
        [showDivider]="true"
        mediaColClasses="text-center"
        [ngStyle]="{
        marginTop: '-'+properHeight+'px'
        }"
        class="col-12 p-0"
      >
        <block-title>
          <fa-icon [icon]="faGraduationCap"></fa-icon>
          Radio Advertising
        </block-title>
        <block-content>
          Pick a country in the region, select the station or ask for advice, send us the creative
          or ask us to make it. Leem Advertising provides a very simple procedure in running Radio Campaigns in
          the Middle East and Africa, all you need to do is send us an email
        </block-content>
        <block-media>
          <app-radio-svg
            [width]="'300px'"
            fillColor="white"
            data-100-center-top="opacity:0;transform:translateX(100px);"
            data--50-center-top="opacity:1;transform:translateX(0px);"
            class="d-block"
          >

          </app-radio-svg>
        </block-media>
      </app-service-block>

      <app-service-block
        #socialAdv
        backgroundColor="#91c540"
        textColor="black"
        [showDivider]="true"
        [textColClasses]="'order-2'"
        [mediaColClasses]="'order-1'"
        [textFromRight]="true"
        [ngStyle]="{
        marginTop: '-'+properHeight+'px'
        }"
        class="col-12 p-0"
      >
        <block-media>
          <app-svg-social-icon [width]="'350px'"></app-svg-social-icon>
        </block-media>
        <block-title>
          <fa-icon [icon]="faGraduationCap"></fa-icon>
          Social Media and Campaign Advertising
        </block-title>
        <block-content>
          An integrated service that aligns well with your Outdoor or Radio Campaign, we have built
          the right connections with our partners in the field to deliver just the right interconnected Media
          Campaign for your brand.
        </block-content>
      </app-service-block>

    </div>
  </div>
</section>
