import {AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild} from '@angular/core';
import {DividerSvgComponent} from '../../../assets/divider-svg/divider-svg.component';

@Component({
  selector: 'app-service-block',
  templateUrl: './service-block.component.html',
  styleUrls: ['./service-block.component.scss']
})
export class ServiceBlockComponent implements AfterViewInit {


  @Input() backgroundColor = 'black';
  @Input() textColor = 'white';
  @Input() showDivider = false;
  @Input() textColClasses = '';
  @Input() titleClasses = 'display-4 mb-5';
  @Input() contentClasses = 'lead';
  @Input() mediaColClasses = '';
  @Input() textFromRight = false;
  @Input() textColumnTransitionStart = '100px';
  @ViewChild('textColumn') textColumn!: ElementRef;
  @ViewChild('topDivider') topDivider!: DividerSvgComponent;

  ngAfterViewInit(): void {
  }

}
