import { Component, OnInit } from '@angular/core';
import {faThumbsUp} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-intro-section',
  templateUrl: './intro-section.component.html',
  styleUrls: ['./intro-section.component.scss']
})
export class IntroSectionComponent implements OnInit {
  thumbsOUpIcon = faThumbsUp;
  constructor() { }

  ngOnInit(): void {
  }

}
