import {AfterContentChecked, Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {BaseComponent} from './base.component';
import {Title} from '@angular/platform-browser';
import {RouterOutlet} from '@angular/router';
import {ViewportScroller} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {IntroDialogComponent} from './intro-dialog/intro-dialog.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('openClose',
      [state('open', style({
        opacity: 1,
      })),
        state('closed', style({
          opacity: 0.1
        })),
        transition('open => closed', [
          animate('1s')
        ]),
        transition('closed => open', [
          animate('0.5s')
        ])])
  ]
})
export class AppComponent extends BaseComponent implements OnInit {

  public showDialogKey = 'leemDialogShowen';
  barsIcon = faBars;

  constructor(titleService: Title,
              private viewportScroller: ViewportScroller,
              public dialog: MatDialog) {
    super(titleService);
  }

  scrollTo(id: any): void {
    this.viewportScroller.scrollToAnchor(id);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(IntroDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      sessionStorage.setItem( this.showDialogKey, 'true');
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    /*if (sessionStorage.getItem(this.showDialogKey) !== 'true') {
      this.openDialog();
    }*/
  }

}
